import { useState, FC } from 'react';
import NavbarSidebarLayout from '../../layouts/navbar-sidebar';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Button, TextInput } from 'flowbite-react';
import { HiHome, HiPlus } from 'react-icons/hi';
import AddProductModal from '../../components/Products/add-product-modal';
import Pagination from '../../components/pagination';
import Loader from '../../components/loader';
import ProductsTable from '../../components/Products/products-table';
import { useProducts } from '../../hooks/api/useProducts';

const ProductsPage: FC = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const { data, isLoading, isError } = useProducts({
    page,
    pageSize,
    searchText: searchQuery,
  });

  const { products = [], totalResults } = data || {};

  const closeAddModel = () => setIsAddOpen(false);
  const { t } = useTranslation();

  if (isError) {
    return <div>{t('products.errorLoading')}</div>;
  }

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">{t('sidebar.home')}</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/products">
                {t('admin.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t('admin.products')}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {t('products.title')}
            </h1>
          </div>

          <div className="sm:flex">
            <form className="lg:pr-3 flex-grow">
              <div className="relative mt-1 lg:w-64 xl:w-96">
                <TextInput
                  id="products-search"
                  name="products-search"
                  placeholder={t('products.searchPlaceholder')}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </form>
            <div className="ml-auto flex items-center space-x-2 sm:space-x-3">
              <Button color="primary" onClick={() => setIsAddOpen(true)}>
                <div className="flex items-center gap-x-3">
                  <HiPlus className="text-xl" />
                  {t('products.addProduct')}
                </div>
              </Button>
              {isAddOpen && (
                <AddProductModal open={isAddOpen} onClose={closeAddModel} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col  ">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              {isLoading ? (
                <Loader className="flex justify-center my-8" />
              ) : (
                <ProductsTable products={products} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Pagination
        currentPage={page}
        totalCount={totalResults as number}
        pageSize={pageSize}
        onPageChange={setPage}
      />
    </NavbarSidebarLayout>
  );
};

export default ProductsPage;
