import { type FC } from 'react';
import { Button, Label, Modal, TextInput } from 'flowbite-react';
import { Contributor } from '../../interfaces/contributor';
import { useTranslation } from 'react-i18next';

export interface EditContributorProps {
  open: boolean;
  onClose: () => void;
  contributor: Contributor; // Add contributor prop
}

const EditContributorModal: FC<EditContributorProps> = function ({
  open,
  onClose,
  contributor,
}) {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} show={open}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('contributorEditModal.title')}</strong>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <Label htmlFor="tradeName">
              {t('contributorEditModal.tradeName')}
            </Label>
            <div className="mt-1">
              <TextInput
                id="tradeName"
                name="tradeName"
                value={contributor.tradeName}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="slug">{t('contributorEditModal.slug')}</Label>
            <div className="mt-1">
              <TextInput id="slug" name="slug" value={contributor.slug} />
            </div>
          </div>
          <div>
            <Label htmlFor="status">{t('contributorEditModal.status')}</Label>
            <div className="mt-1">
              <TextInput
                id="status"
                name="status"
                value={
                  contributor.isRegistered
                    ? 'Registered'
                    : 'Not Registered' || ''
                }
              />
            </div>
          </div>
          <div>
            <Label htmlFor="postalCode">
              {t('contributorEditModal.postalCode')}
            </Label>
            <div className="mt-1">
              <TextInput
                id="postalCode"
                name="postalCode"
                value={contributor.postalCode}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="city">{t('contributorEditModal.city')}</Label>
            <div className="mt-1">
              <TextInput id="city" name="city" value={contributor.city} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="primary"
          onClick={onClose}
          aria-label={t('contributorEditModal.saveButton')}
        >
          {t('contributorEditModal.saveButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContributorModal;
