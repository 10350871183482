import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import {
  Contributor,
  FetchContributorsParams,
} from '../../interfaces/contributor';

interface ContributorsResponse {
  contributors: Contributor[];
  page: number;
  pageSize: number;
  totalResults: number;
}

const fetchContributors = async ({
  page,
  pageSize,
  searchText,
}: FetchContributorsParams) => {
  const queryParams: Record<string, any> = {
    page,
    pageSize,
  };

  if (searchText && searchText.trim()) {
    queryParams.searchText = searchText.trim();
  }

  try {
    const { data } = await axiosInstance.get('/contributors', {
      params: queryParams,
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching contributors:', error);
    throw new Error('Failed to fetch contributors');
  }
};

export const useContributors = ({
  page,
  pageSize,
  searchText = '',
}: FetchContributorsParams) => {
  return useQuery<ContributorsResponse>({
    queryKey: [
      'contributors',
      { page, pageSize, searchText: searchText.trim() },
    ],
    queryFn: () =>
      fetchContributors({ page, pageSize, searchText: searchText.trim() }),
  });
};
