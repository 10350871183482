import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { DEFAULT_LOCALE, validLanguages } from './configs/constants';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'localStorageLanguageDetector',
  lookup: () => {
    const selectedLanguage = localStorage.getItem('language');

    return validLanguages.includes(selectedLanguage!)
      ? selectedLanguage!
      : DEFAULT_LOCALE;
  },
  cacheUserLanguage: (lng: string) => {
    localStorage.setItem('language', lng);
  },
});

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LOCALE,
    debug: import.meta.env.VITE_NODE_ENV !== 'production',
    detection: {
      order: ['localStorageLanguageDetector', 'navigator', 'htmlTag'],
      caches: ['localStorageLanguageDetector'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

export default i18n;
