import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { analytics, logEvent } from './firebaseConfig';

const useRouteChange = () => {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // 1. Enregistrer des informations supplémentaires
      const pageTitle = document.title;
      const previousUrl = location.pathname;

      // 2. Ajouter une journalisation personnalisée
      console.log(`Navigated to ${url} from ${previousUrl}`);

      // 3. Intégration avec Firebase Analytics
      if (analytics) {
        logEvent(analytics, 'page_view', {
          page_path: url,
          page_title: pageTitle,
          previous_path: previousUrl,
        });
      }

      // 4. Suivi des performances
      const startTime = performance.now();

      // 5. Mesurer le temps de chargement de la nouvelle page
      const handleLoad = () => {
        const endTime = performance.now();
        const loadTime = endTime - startTime;
        console.log(`Page load time for ${url}: ${loadTime}ms`);
        window.removeEventListener('load', handleLoad);
      };

      window.addEventListener('load', handleLoad);
    };

    // Écoute les changements de localisation
    handleRouteChange(location.pathname);
  }, [location]);
};

export const logButtonClick = (buttonName: string) => {
  if (analytics) {
    logEvent(analytics, 'button_click', {
      button_name: buttonName,
    });
  }
};

export default useRouteChange;
