import { type FC } from 'react';
import { Button, Modal, TextInput } from 'flowbite-react';

interface AddProductProps {
  open: boolean;
  onClose: () => void;
}

const AddProductModal: FC<AddProductProps> = function ({ open, onClose }) {
  return (
    <Modal onClose={onClose} show={open}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>Add new product</strong>
      </Modal.Header>
      <Modal.Body>
        <TextInput
          id="productName"
          name="productName"
          placeholder="Product Name"
        />
        <TextInput
          id="productSlug"
          name="productSlug"
          placeholder="Product Slug"
        />
        <TextInput
          id="productPrice"
          name="productPrice"
          placeholder="Product Price"
          type="number"
        />
        <TextInput
          id="productStock"
          name="productStock"
          placeholder="Product Stock"
          type="number"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={onClose}>
          Add Product
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProductModal;
