import { RouteInterface } from '../interfaces/Route';
import DashboardPage from '../pages';
import ContributorsPage from '../pages/admin/contributors';
import LocationsPage from '../pages/admin/locations';
import ProductsPage from '../pages/admin/products';
import ForgotPasswordPage from '../pages/authentication/forgot-password';
import ProfileLockPage from '../pages/authentication/profile-lock';
import ResetPasswordPage from '../pages/authentication/reset-password';
import SignInPage from '../pages/authentication/sign-in';
import SignUpPage from '../pages/authentication/sign-up';
import EcommerceBillingPage from '../pages/business/billing';
import EcommerceInformationsPage from '../pages/business/informations';
import EcommerceInvoicePage from '../pages/business/invoice';
import EcommerceLocationsPage from '../pages/business/locations';
import EcommerceProductsPage from '../pages/business/products';
import KanbanPage from '../pages/kanban';
import MailingComposePage from '../pages/mailing/compose';
import MailingInboxPage from '../pages/mailing/inbox';
import MailingReadPage from '../pages/mailing/read';
import MailingReplyPage from '../pages/mailing/reply';
import NotFoundPage from '../pages/pages/404';
import ServerErrorPage from '../pages/pages/500';
import MaintenancePage from '../pages/pages/maintenance';
import UserFeedPage from '../pages/users/feed';
import UserListPage from '../pages/users/list';
import UserProfilePage from '../pages/users/profile';
import UserSettingsPage from '../pages/users/settings';

export const DEFAULT = '/';
export const MAILINGCOMPOSE = '/mailing/compose';
export const MAILINGINBOX = '/mailing/inbox';
export const MAILINGREAD = '/mailing/read';
export const MAILINGREPLY = '/mailing/reply';
export const KANBAN = '/kanban';
export const PRICING = '/pages/pricing';
export const MAINTENANCE = '/pages/maintenance';
export const NOTFOUND = '/pages/404';
export const ERROR = '/pages/500';
export const SIGNIN = '/authentication/sign-in';
export const SIGNUP = '/authentication/sign-up';
export const FORGOTPASSWORD = '/authentication/forgot-password';
export const RESETPASSWORD = '/authentication/reset-password';
export const PROFILELOCK = '/authentication/profile-lock';
export const BILLING = '/business/billing';
export const INVOICE = '/business/invoice';
export const INFORMATIONS = '/business/informations';
export const LOCATIONS = '/business/locations';
export const PRODUCTS = '/business/products';
export const CONTRIBUTORS = '/admin/contributors';
export const ADMIN_PRODUCTS = '/admin/products';
export const ADMIN_LOCATIONS = '/admin/locations';
export const FEED = '/users/feed';
export const USERLIST = '/users/list';
export const USERPROFILE = '/users/profile';
export const USERSETTINGS = '/users/settings';

export const ROUTES: RouteInterface[] = [
  {
    key: 'DEFAULT',
    route: DEFAULT,
    private: true,
    component: DashboardPage,
  },
  {
    key: 'MAILINGCOMPOSE',
    route: MAILINGCOMPOSE,
    private: true,
    component: MailingComposePage,
  },
  {
    key: 'MAILINGINBOX',
    route: MAILINGINBOX,
    private: true,
    component: MailingInboxPage,
  },
  {
    key: 'MAILINGREAD',
    route: MAILINGREAD,
    private: true,
    component: MailingReadPage,
  },
  {
    key: 'MAILINGREPLY',
    route: MAILINGREPLY,
    private: true,
    component: MailingReplyPage,
  },
  {
    key: 'KANBAN',
    route: KANBAN,
    private: true,
    component: KanbanPage,
  },
  {
    key: 'MAINTENANCE',
    route: MAINTENANCE,
    private: true,
    component: MaintenancePage,
  },
  {
    key: 'NOTFOUND',
    route: NOTFOUND,
    private: true,
    component: NotFoundPage,
  },
  {
    key: 'ERROR',
    route: ERROR,
    private: true,
    component: ServerErrorPage,
  },
  {
    key: 'SIGNIN',
    route: SIGNIN,
    private: false,
    component: SignInPage,
  },
  {
    key: 'SIGNUP',
    route: SIGNUP,
    private: false,
    component: SignUpPage,
  },
  {
    key: 'FORGOTPASSWORD',
    route: FORGOTPASSWORD,
    private: false,
    component: ForgotPasswordPage,
  },
  {
    key: 'RESETPASSWORD',
    route: RESETPASSWORD,
    private: false,
    component: ResetPasswordPage,
  },
  {
    key: 'PROFILELOCK',
    route: PROFILELOCK,
    private: true,
    component: ProfileLockPage,
  },
  {
    key: 'BILLING',
    route: BILLING,
    private: true,
    component: EcommerceBillingPage,
  },
  {
    key: 'INVOICE',
    route: INVOICE,
    private: true,
    component: EcommerceInvoicePage,
  },
  {
    key: 'INFORMATIONS',
    route: INFORMATIONS,
    private: true,
    component: EcommerceInformationsPage,
  },
  {
    key: 'LOCATIONS',
    route: LOCATIONS,
    private: true,
    component: EcommerceLocationsPage,
  },
  {
    key: 'PRODUCTS',
    route: PRODUCTS,
    private: true,
    component: EcommerceProductsPage,
  },
  {
    key: 'CONTRIBUTORS',
    route: CONTRIBUTORS,
    private: true,
    component: ContributorsPage,
  },
  {
    key: 'ADMIN_PRODUCTS',
    route: ADMIN_PRODUCTS,
    private: true,
    component: ProductsPage,
  },
  {
    key: 'ADMIN_LOCATIONS',
    route: ADMIN_LOCATIONS,
    private: true,
    component: LocationsPage,
  },
  {
    key: 'FEED',
    route: FEED,
    private: true,
    component: UserFeedPage,
  },
  {
    key: 'USERLIST',
    route: USERLIST,
    private: true,
    component: UserListPage,
  },
  {
    key: 'USERPROFILE',
    route: USERPROFILE,
    private: true,
    component: UserProfilePage,
  },
  {
    key: 'USERSETTINGS',
    route: USERSETTINGS,
    private: true,
    component: UserSettingsPage,
  },
];
