import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { ILocation, FetchLocationParams } from '../../interfaces/location';

interface LocationResponse {
  locations: ILocation[];
  page: number;
  pageSize: number;
  totalResults: number;
}

const fetchLocations = async ({
  page,
  pageSize,
  searchText,
}: FetchLocationParams) => {
  const queryParams: Record<string, any> = {
    page,
    pageSize,
  };

  if (searchText && searchText.trim()) {
    queryParams.searchText = searchText.trim();
  }

  try {
    const { data } = await axiosInstance.get('/contributor/locations', {
      params: queryParams,
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching locations:', error);
    throw new Error('Failed to locations');
  }
};

export const useLocations = ({
  page,
  pageSize,
  searchText = '',
}: FetchLocationParams) => {
  return useQuery<LocationResponse>({
    queryKey: ['locations', { page, pageSize, searchText: searchText.trim() }],
    queryFn: () =>
      fetchLocations({ page, pageSize, searchText: searchText.trim() }),
  });
};
