import axios from 'axios';
import { SIGNIN } from '../paths';

function getSessionCookieValue(key: string): string | null {
  const sessionCookie = getCookie('__session');
  if (!sessionCookie) return null;
  try {
    const decodedCookie = decodeURIComponent(sessionCookie);
    const sessionData = JSON.parse(decodedCookie);
    return sessionData[key] || null;
  } catch (error) {
    console.error('Error parsing session cookie:', error);
    return null;
  }
}

function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const cookieValue = parts.pop();
    if (cookieValue) {
      return cookieValue.split(';').shift() || null;
    }
  }

  return null;
}

const axiosInstance = axios.create({
  baseURL: process.env.VITE_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Récupération du token (si existant) du localStorage pour l'ajouter dans l'en-tête Authorization
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const xsrfToken = getSessionCookieValue('xsrfToken');
    if (xsrfToken && config.method !== 'get') {
      config.headers['x-xsrf-token'] = xsrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if server is down
    if (error.code == 'ERR_NETWORK') return error;

    // throw login error to be manipulated elsewhere
    if (
      error.response.status === 401 &&
      error.response.data.message === 'Invalid credentials'
    ) {
      return error;
    }
    if (error.response.status === 401) {
      window.location.href = SIGNIN;
    }
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
