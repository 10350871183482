import { type FC } from 'react';
import { Button, Label, Modal, TextInput } from 'flowbite-react';
import { Product } from '../../interfaces/product';
import { useTranslation } from 'react-i18next';

export interface EditProductProps {
  open: boolean;
  onClose: () => void;
  product: Product;
}

const EditProductModal: FC<EditProductProps> = function ({
  open,
  onClose,
  product,
}) {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} show={open}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('productsEditModal.editProduct')}</strong>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <Label htmlFor="productName">
              {t('productsEditModal.productName')}
            </Label>
            <div className="mt-1">
              <TextInput
                id="productName"
                name="productName"
                value={product.name}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="productSlug">
              {t('productsEditModal.productSlug')}
            </Label>
            <div className="mt-1">
              <TextInput
                id="productSlug"
                name="productSlug"
                value={product.slug}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="productDesc">
              {t('productsEditModal.productDescription')}
            </Label>
            <div className="mt-1">
              <TextInput
                id="productDesc"
                name="productDesc"
                value={product.description}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={onClose}>
          {t('productsEditModal.saveButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProductModal;
