import { FC, useState } from 'react';
import { Table, Button } from 'flowbite-react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { Contributor } from '../../interfaces/contributor';
import { useTranslation } from 'react-i18next';
import EditContributorModal from './edit-contributor-modal';

const ContributorsTable: FC<{ contributors: Contributor[] }> = ({
  contributors,
}) => {
  const [selectedContributor, setSelectedContributor] =
    useState<Contributor | null>(null);

  const [isEditOpen, setOpenEdit] = useState(false);
  const closeEditModel = () => setOpenEdit(false);
  const { t } = useTranslation();
  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.name')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.slug')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.postalCode')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.city')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.createdAt')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap ">
          {t('contributorTable.lastLogin')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.status')}
        </Table.HeadCell>
        <Table.HeadCell className="whitespace-nowrap">
          {t('contributorTable.actions')}
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
        {contributors.map((contributor) => (
          <Table.Row
            key={contributor.id}
            className="hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <Table.Cell className="whitespace-nowrap p-4 text-base font-semibold text-gray-900 dark:text-white">
              <div>{contributor.tradeName}</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {contributor.email}
              </div>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
              {contributor.slug}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
              {contributor.postalCode}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
              {contributor.city}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
              {new Date(contributor.createdAt).toLocaleDateString()}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
              {contributor.lastLoginDate
                ? new Date(contributor.lastLoginDate).toLocaleDateString()
                : t('contributorTable.empty')}
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white">
              {contributor.isRegistered
                ? t('contributorTable.registered')
                : t('contributorTable.notRegistered')}
            </Table.Cell>
            <Table.Cell>
              <div className="flex items-center gap-x-3 whitespace-nowrap">
                <Button
                  color="primary"
                  aria-label={t('contributorTable.editContributor')}
                  onClick={() => {
                    setSelectedContributor(contributor);
                    setOpenEdit(true);
                  }}
                >
                  <div className="flex items-center gap-x-2">
                    <HiOutlinePencilAlt className="text-lg" />
                    {t('contributorTable.editContributor')}
                  </div>
                </Button>
                {isEditOpen && selectedContributor && (
                  <EditContributorModal
                    open={isEditOpen}
                    onClose={closeEditModel}
                    contributor={selectedContributor}
                  />
                )}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ContributorsTable;
