import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const hasNextPage = currentPage * pageSize < totalCount;

  return (
    <div className="sticky bottom-0 right-0 w-full items-center border-t border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex sm:justify-between">
      <div className="mb-4 flex items-center sm:mb-0">
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {t('pagination.showing')}&nbsp;
          <span className="font-semibold text-gray-900 dark:text-white">
            {currentPage * pageSize - pageSize + 1}-
            {Math.min(currentPage * pageSize, totalCount)}
          </span>
          &nbsp;{t('pagination.of')}&nbsp;
          <span className="font-semibold text-gray-900 dark:text-white">
            {totalCount}
          </span>
        </span>
      </div>
      <div className="flex items-center space-x-3">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`inline-flex items-center justify-center rounded-lg px-3 py-2 text-center text-sm font-medium ${
            currentPage === 1
              ? 'text-gray-300 cursor-not-allowed'
              : 'bg-primary-700 text-white hover:bg-primary-800'
          }`}
        >
          <HiChevronLeft className="mr-1 text-base" />
          {t('pagination.previous')}
        </button>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={!hasNextPage}
          className={`inline-flex items-center justify-center rounded-lg px-3 py-2 text-center text-sm font-medium ${
            !hasNextPage
              ? 'text-gray-300 cursor-not-allowed'
              : 'bg-primary-700 text-white hover:bg-primary-800'
          }`}
        >
          {t('pagination.next')}
          <HiChevronRight className="ml-1 text-base" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
