import { Button, Label, TextInput } from 'flowbite-react';
import { useState, type FC } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useAuth } from '../../providers/AuthProvider';
import { LoginCredentials } from '../../interfaces/userInterfaces';
import { EMAIL_REGEX_PATTERN, PASSWORD_REGEX_PATTERN } from '../../utils/const';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const SignInPage: FC = function () {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginCredentials>();
  const { login } = useAuth();
  const [error, setError] = useState<string | undefined>();

  const onSubmit = async (data: LoginCredentials) => {
    try {
      await login(data);
    } catch (error) {
      if (error instanceof AxiosError && error.message === 'Network Error') {
        setError(error.message);
      }
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data.message === 'Invalid credentials'
      )
        setError(t('signin.invalidCredentialsMessage'));
    }
  };

  return (
    <div className="m-6 flex flex-col gap-y-3 justify-center items-center min-h-screen">
      <a href="/" className="mb-6 flex items-center gap-x-1 lg:my-0">
        <img alt="Cacao logo" src="/logo.webp" className="mr-3 h-10" />
        <span className="font-gagalin self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
          {t('cacao.title')}
        </span>
      </a>
      <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
          {t('signin.title')}
        </h1>
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 flex flex-col gap-y-1">
            <Label htmlFor="email">{t('signin.email')}</Label>
            <TextInput
              id="email"
              placeholder={t('signin.emailPlaceholder')}
              type="text"
              {...register('email', {
                required: t('signin.emailRequiredMessage'),
                pattern: {
                  value: EMAIL_REGEX_PATTERN,
                  message: t('signin.invalidEmailMessage'),
                },
              })}
            />
            {errors.email && (
              <span className="text-sm text-red-500">
                {(errors.email as FieldError)?.message}
              </span>
            )}
          </div>
          <div>
            <Label htmlFor="password">{t('signin.password')}</Label>
            <TextInput
              id="password"
              placeholder={t('signin.passwordPlaceholder')}
              type="password"
              {...register('password', {
                required: t('signin.passwordRequiredMessage'),
                pattern: {
                  value: PASSWORD_REGEX_PATTERN,
                  message: t('signin.invalidPasswordMessage'),
                },
              })}
            />
            {errors.password && (
              <span className="text-sm text-red-500">
                {(errors.password as FieldError)?.message}
              </span>
            )}
          </div>
          {error && <span className="text-sm text-red-500">{error}</span>}
          {/*<div className="flex items-start">*/}
          {/*  <div className="flex items-center gap-x-3">*/}
          {/*    <Checkbox id="rememberMe" name="rememberMe" />*/}
          {/*    <Label htmlFor="rememberMe">{t('signin.rememberMe')}</Label>*/}
          {/*  </div>*/}
          {/*  <a*/}
          {/*    href="#"*/}
          {/*    className="w-1/2 text-right text-sm text-primary-600 dark:text-primary-300"*/}
          {/*  >*/}
          {/*    {t('signin.lostPassword')}*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="mb-6">
            <Button type="submit" className="w-full lg:w-auto">
              {t('signin.login')}
            </Button>
          </div>
          {/*<p className="text-sm text-gray-500 dark:text-gray-300">*/}
          {/*  {t('signin.notRegistered')}&nbsp;*/}
          {/*  <a href="#" className="text-primary-600 dark:text-primary-300">*/}
          {/*    {t('signin.register')}*/}
          {/*  </a>*/}
          {/*</p>*/}
        </form>
      </div>
    </div>
  );
};

export default SignInPage;
