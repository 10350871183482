import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Product, FetchProductParams } from '../../interfaces/product';

interface ProductsResponse {
  products: Product[];
  page: number;
  pageSize: number;
  totalResults: number;
}

const fetchProducts = async ({
  page,
  pageSize,
  searchText,
}: FetchProductParams) => {
  const queryParams: Record<string, any> = {
    page,
    pageSize,
  };

  if (searchText && searchText.trim()) {
    queryParams.searchText = searchText.trim();
  }

  try {
    const { data } = await axiosInstance.get('/products', {
      params: queryParams,
    });
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching products:', error);
    throw new Error('Failed to fetch products');
  }
};

export const useProducts = ({
  page,
  pageSize,
  searchText = '',
}: FetchProductParams) => {
  return useQuery<ProductsResponse>({
    queryKey: ['products', { page, pageSize, searchText: searchText.trim() }],
    queryFn: () =>
      fetchProducts({ page, pageSize, searchText: searchText.trim() }),
  });
};
