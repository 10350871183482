import { FC, useMemo } from 'react';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Textarea,
  Select,
  Checkbox,
} from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ILocation, LocationType } from '../../interfaces/location';

interface EditLocationModalProps {
  open: boolean;
  onClose: () => void;
  location: ILocation;
}

export interface FormValues {
  name: string;
  slug: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: string;
  longitude: string;
  googleMapsLink: string;
  locationType: LocationType;
  openingHours: string;
  exceptionalClosures: string;
  consultableDates: string;
  openingDates: string;
  contactPhone: string;
  contactEmail: string;
  website: string;
  socialMediaLinks: string;
  tags: string;
  public: boolean;
  description: string;
}

const EditLocationModal: FC<EditLocationModalProps> = ({
  open,
  onClose,
  location,
}) => {
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => ({
      name: location.name,
      slug: location.slug || '',
      address: location.address || '',
      city: location.city,
      postalCode: location.postalCode || '',
      country: location.country || '',
      latitude: location.latitude ? location.latitude.toString() : '',
      longitude: location.longitude ? location.longitude.toString() : '',
      googleMapsLink: location.googleMapsLink || '',
      locationType: location.locationType,
      openingHours: location.openingHours
        ? JSON.stringify(location.openingHours)
        : '',
      exceptionalClosures: location.exceptionalClosures
        ? JSON.stringify(location.exceptionalClosures)
        : '',
      consultableDates: location.consultableDates
        ? JSON.stringify(location.consultableDates)
        : '',
      openingDates: location.openingDates
        ? JSON.stringify(location.openingDates)
        : '',
      contactPhone: location.contactPhone || '',
      contactEmail: location.contactEmail || '',
      website: location.website || '',
      socialMediaLinks: location.socialMediaLinks
        ? JSON.stringify(location.socialMediaLinks)
        : '',
      tags: location.tags ? location.tags.join(', ') : '',
      public: location.public,
      description: location.description || '',
    }),
    [location]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const onSubmit = (data: any) => {
    const updatedLocation = {
      ...data,
      openingHours: JSON.parse(data.openingHours),
      exceptionalClosures: JSON.parse(data.exceptionalClosures),
      consultableDates: JSON.parse(data.consultableDates),
      openingDates: JSON.parse(data.openingDates),
      socialMediaLinks: JSON.parse(data.socialMediaLinks),
      tags: data.tags.split(',').map((tag: any) => tag.trim()),
    };

    // Replace this with your mutation or API call to save the updated location
    console.log('Updated Location:', updatedLocation);
    onClose();
  };

  return (
    <Modal onClose={onClose} show={open} size="4xl">
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('editLocationModal.title')}</strong>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {/* Form Fields */}
            {[
              { label: t('editLocationModal.name'), id: 'name', type: 'text' },
              { label: t('editLocationModal.slug'), id: 'slug', type: 'text' },
              {
                label: t('editLocationModal.address'),
                id: 'address',
                type: 'text',
              },
              { label: t('editLocationModal.city'), id: 'city', type: 'text' },
              {
                label: t('editLocationModal.postalCode'),
                id: 'postalCode',
                type: 'text',
              },
              {
                label: t('editLocationModal.country'),
                id: 'country',
                type: 'text',
              },
              {
                label: t('editLocationModal.googleMapsLink'),
                id: 'googleMapsLink',
                type: 'text',
              },
              {
                label: t('editLocationModal.contactPhone'),
                id: 'contactPhone',
                type: 'text',
              },
              {
                label: t('editLocationModal.contactEmail'),
                id: 'contactEmail',
                type: 'email',
              },
              {
                label: t('editLocationModal.website'),
                id: 'website',
                type: 'text',
              },
              {
                label: t('editLocationModal.latitude'),
                id: 'latitude',
                type: 'number',
              },
              {
                label: t('editLocationModal.longitude'),
                id: 'longitude',
                type: 'number',
              },
              { label: t('editLocationModal.tags'), id: 'tags', type: 'text' },
              {
                label: t('editLocationModal.description'),
                id: 'description',
                type: 'textarea',
              },
            ].map((field) => (
              <div key={field.id}>
                <Label htmlFor={field.id}>{field.label}</Label>
                <div className="mt-1">
                  {field.type === 'textarea' ? (
                    <Textarea
                      id={field.id}
                      {...register(field.id as keyof FormValues)}
                    />
                  ) : (
                    <TextInput
                      id={field.id}
                      type={field.type}
                      {...register(field.id as keyof FormValues)}
                    />
                  )}
                  {errors[field.id as keyof FormValues] && (
                    <p className="text-red-500">
                      {errors[field.id as keyof FormValues]?.message}
                    </p>
                  )}
                </div>
              </div>
            ))}

            {/* Location Type */}
            <div>
              <Label htmlFor="locationType">
                {t('editLocationModal.locationType')}
              </Label>
              <div className="mt-1">
                <Select id="locationType" {...register('locationType')}>
                  {Object.values(LocationType).map((type) => (
                    <option key={type} value={type}>
                      {t(`locationType.${type}`)}
                    </option>
                  ))}
                </Select>
                {errors.locationType && (
                  <p className="text-red-500">{errors.locationType?.message}</p>
                )}
              </div>
            </div>

            {/* Opening Hours, Exceptional Closures, etc. */}
            <div>
              <Label htmlFor="openingHours">
                {t('editLocationModal.openingHours')}
              </Label>
              <TextInput id="openingHours" {...register('openingHours')} />
              {errors.openingHours && (
                <p className="text-red-500">{errors.openingHours?.message}</p>
              )}
            </div>
            <div>
              <Label htmlFor="exceptionalClosures">
                {t('editLocationModal.exceptionalClosures')}
              </Label>
              <TextInput
                id="exceptionalClosures"
                {...register('exceptionalClosures')}
              />
              {errors.exceptionalClosures && (
                <p className="text-red-500">
                  {errors.exceptionalClosures?.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="consultableDates">
                {t('editLocationModal.consultableDates')}
              </Label>
              <TextInput
                id="consultableDates"
                {...register('consultableDates')}
              />
              {errors.consultableDates && (
                <p className="text-red-500">
                  {errors.consultableDates?.message}
                </p>
              )}
            </div>
            <div>
              <Label htmlFor="openingDates">
                {t('editLocationModal.openingDates')}
              </Label>
              <TextInput id="openingDates" {...register('openingDates')} />
              {errors.openingDates && (
                <p className="text-red-500">{errors.openingDates?.message}</p>
              )}
            </div>
            <div>
              <Label htmlFor="socialMediaLinks">
                {t('editLocationModal.socialMediaLinks')}
              </Label>
              <TextInput
                id="socialMediaLinks"
                {...register('socialMediaLinks')}
              />
              {errors.socialMediaLinks && (
                <p className="text-red-500">
                  {errors.socialMediaLinks?.message}
                </p>
              )}
            </div>

            {/* Public Checkbox */}
            <div>
              <Label htmlFor="public">{t('editLocationModal.public')}</Label>
              <div className="mt-1">
                <Checkbox id="public" {...register('public')} />
              </div>
              {errors.public && (
                <p className="text-red-500">{errors.public?.message}</p>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" color="primary">
            {t('editLocationModal.saveButton')}
          </Button>
          <Button color="gray" onClick={onClose}>
            {t('editLocationModal.cancelButton')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditLocationModal;
