import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { SIGNIN } from '../../paths';
import { useAuth } from '../../providers/AuthProvider';

interface PrivateRouteProps {
  child: any;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ child }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={SIGNIN} replace />;
  } else {
    return child;
  }
};

export default PrivateRoute;
