import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './i18n';
import './index.css';
import './utils/sentryConfig.js';
import App from './App';
import { setupYupI18n } from './configs/i18n.ts';

const container = document.getElementById('root');

if (!container) {
  throw new Error("React root element doesn't exist!");
}

const root = createRoot(container);

setupYupI18n();

root.render(
  <StrictMode>
    <Router>
      <App />
    </Router>
  </StrictMode>
);
