import { Footer } from 'flowbite-react';
import type { FC, PropsWithChildren } from 'react';
import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import { MdFacebook } from 'react-icons/md';
import { FaInstagram } from 'react-icons/fa';
import { SidebarProvider, useSidebarContext } from '../context/SidebarContext';
import classNames from 'classnames';

interface NavbarSidebarLayoutProps {
  isFooter?: boolean;
}

const NavbarSidebarLayout: FC<PropsWithChildren<NavbarSidebarLayoutProps>> =
  function ({ children, isFooter = true }) {
    return (
      <SidebarProvider>
        <Navbar />
        <div className="flex items-start pt-16">
          <Sidebar />
          <MainContent isFooter={isFooter}>{children}</MainContent>
        </div>
      </SidebarProvider>
    );
  };

const MainContent: FC<PropsWithChildren<NavbarSidebarLayoutProps>> = function ({
  children,
  isFooter,
}) {
  const { isOpenOnSmallScreens: isSidebarOpen } = useSidebarContext();

  return (
    <main
      className={classNames(
        'overflow-y-auto relative w-full h-full bg-gray-50 dark:bg-gray-900',
        isSidebarOpen ? 'lg:ml-16' : 'lg:ml-64'
      )}
    >
      {children}
      {isFooter && (
        <div className="mx-4 mt-4">
          <MainContentFooter />
        </div>
      )}
    </main>
  );
};

const MainContentFooter: FC = function () {
  const isTemplateEnabled = import.meta.env.VITE_TEMPLATE_ENABLED === 'true';

  return (
    <>
      <Footer container>
        <div className="flex w-full flex-col gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0">
          {isTemplateEnabled && (
            <Footer.LinkGroup>
              <Footer.Link href="#" className="mr-3 mb-3 lg:mb-0">
                Terms and conditions
              </Footer.Link>
              <Footer.Link href="#" className="mr-3 mb-3 lg:mb-0">
                Privacy Policy
              </Footer.Link>
              <Footer.Link href="#" className="mr-3">
                Licensing
              </Footer.Link>
              <Footer.Link href="#" className="mr-3">
                Cookie Policy
              </Footer.Link>
              <Footer.Link href="#">Contact</Footer.Link>
            </Footer.LinkGroup>
          )}
          <Footer.LinkGroup>
            <div className="flex gap-4 md:gap-2">
              <Footer.Link
                href="https://www.facebook.com/profile.php?id=100090737314501"
                target={'_blank'}
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <MdFacebook className="text-lg" />
              </Footer.Link>
              <Footer.Link
                href="https://www.instagram.com/cacao_app/"
                target={'_blank'}
                className="hover:[&>*]:text-black dark:hover:[&>*]:text-gray-300"
              >
                <FaInstagram className="text-lg" />
              </Footer.Link>
            </div>
          </Footer.LinkGroup>
        </div>
      </Footer>
      <p className="my-8 text-center text-sm text-gray-500 dark:text-gray-300">
        &copy; 2024{' '}
        <a
          href="https://coresoftdevelopment.fr"
          target={'_blank'}
          rel="noreferrer"
        >
          Core Soft Development
        </a>
        . All rights reserved.
      </p>
    </>
  );
};

export default NavbarSidebarLayout;
