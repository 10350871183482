import { type FC } from 'react';
import { Button, Label, Modal, TextInput } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

interface AddContributorProps {
  open: boolean;
  onClose: () => void;
}

const AddContributorModal: FC<AddContributorProps> = function ({
  open,
  onClose,
}) {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} show={open}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('contributorAddModal.title')}</strong>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <Label htmlFor="tradeName">
              {t('contributorAddModal.tradeName')}
            </Label>
            <div className="mt-1">
              <TextInput id="tradeName" name="tradeName" />
            </div>
          </div>
          <div>
            <Label htmlFor="slug">{t('contributorAddModal.slug')}</Label>
            <div className="mt-1">
              <TextInput id="slug" name="slug" />
            </div>
          </div>
          <div>
            <Label htmlFor="status">{t('contributorAddModal.status')}</Label>
            <div className="mt-1">
              <TextInput id="status" name="status" />
            </div>
          </div>
          <div>
            <Label htmlFor="postalCode">
              {t('contributorAddModal.postalCode')}
            </Label>
            <div className="mt-1">
              <TextInput id="postalCode" name="postalCode" />
            </div>
          </div>
          <div>
            <Label htmlFor="city">{t('contributorAddModal.city')}</Label>
            <div className="mt-1">
              <TextInput id="city" name="city" />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="primary"
          onClick={onClose}
          aria-label={t('contributorAddModal.saveButton')}
        >
          {t('contributorAddModal.saveButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContributorModal;
