import { FC, useState } from 'react';
import { Button, Label, Modal, TextInput } from 'flowbite-react';
import { ILocation, LocationType } from '../../interfaces/location';
import { useTranslation } from 'react-i18next';

interface AddLocationModalProps {
  open: boolean;
  onClose: () => void;
}

const AddLocationModal: FC<AddLocationModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<ILocation>({
    name: '',
    city: '',
    locationType: LocationType.STORE,
    public: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal onClose={onClose} show={open}>
      <Modal.Header>
        <strong>{t('locationAddModal.addLocation')}</strong>
      </Modal.Header>
      <Modal.Body>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <Label htmlFor="name">{t('locationAddModal.locationName')}</Label>
            <TextInput
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="city">{t('locationAddModal.city')}</Label>
            <TextInput
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="locationType">
              {t('locationAddModal.locationType')}
            </Label>
            <TextInput
              id="locationType"
              name="locationType"
              value={formData.locationType}
              onChange={handleChange}
            />
          </div>
          {/* Add fields for latitude, longitude, and other optional fields as needed */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={() => onClose()}>
          {t('locationAddModal.addButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLocationModal;
